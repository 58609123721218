import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import IndexTable from "./IndexTable";
import Cookies from 'js-cookie';
import Create from "../../reclamation/Create";
import Configurator from '../../reclamation/Configurator'; 
function Reclamation(props) {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0); 
    useEffect(() => {
        
    }, []);
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Alle Reklamationen')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable refresh={refresh} projectId={props.projectId}/>
                        </div>
                    </div>
                </div>
            </div>

            <Create setrefresh={setRefresh} />
        </>
    );
}

export default Reclamation;
