import { useMemo, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import { GoogleMap, useLoadScript, Rectangle, OverlayView } from '@react-google-maps/api';


const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
})

function RoofPlannerV3(props) {
    let params = useParams();

    const [drawing, setDrawing] = useState(false);
    const [boudsSelected, setBoudsSelected] = useState(false);
    const [rotateing, setRotateing] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [startPoint, setStartPoint] = useState(null);
    const [endPoint, setEndPoint] = useState(null);
    const [totalLeftRactangle, setTotalLeftRactangle] = useState(0);
    const [totalRightRactangle, setTotalRightRactangle] = useState(0);
    const [totalTopRactangle, setTotalTopRactangle] = useState(0);
    const [totalBottomRactangle, setTotalBottomRactangle] = useState(0);
    const [width, setWidth] = useState(998); //mm
    const [height, setHeight] = useState(1690); //mm
    const [widthMetersToLatitude, setWidthMetersToLatitude] = useState(((width * 0.001) / 2) / 111000); // 1 degree of latitude ≈ 111,000 meters
    const [heightMetersToLatitude, setHeightMetersToLatitude] = useState(((height * 0.001) / 2) / 111000); // 1 degree of latitude ≈ 111,000 meters

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBuEcSXUNCT3f9WrObXFVEKr7DDJu64MpU',
        libraries: ["places"]
    });

    const [bounds, setBounds] = useState([]);
    const [panelGroups, setPanelGroups] = useState([]);
    const [mapZoom, setMapZoom] = useState(22);
    const [mapCenter, setMapCenter] = useState({ lat: 37.44635467220939, lng: -122.13860325160785 });
    const [position, setPosition] = useState({ lat: 0, lng: 0 });

    const addPanels = () => {
        setDrawing(true);
    }

    const mapRef = useRef(null);

    function handleLoad(map) {
        mapRef.current = map;
    }

    function handleCenterChanged() {
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        setPosition(newPos);
    }
    
    /*
    const handleMouseUp = () => {
        console.log('handleMouseUp');
        //setDrawing(false);
    };
    */

    const handleMapClick = (event) => {
        console.log('handleMapClick');

        if (drawing && boudsSelected === false) {
            const latLng = event.latLng && event.latLng.toJSON(); // Get the clicked LatLng as JSON

            setStartPoint(latLng);
            var currentBounds = bounds;
            var currentpanelGroups = panelGroups;
            currentBounds.push({
                north: latLng?.lat + heightMetersToLatitude,
                south: latLng?.lat - heightMetersToLatitude,
                east: latLng?.lng + widthMetersToLatitude,
                west: latLng?.lng - widthMetersToLatitude,
            });

            latLng.angle = 0;
            currentpanelGroups.push(latLng);

            setBounds(currentBounds);
            setPanelGroups(currentpanelGroups);
            setBoudsSelected(true);
            setRefresh(refresh + 1)

        } else {
            setBoudsSelected(false);
        }
        setDrawing(false);
    };
    
    /*
    const handleMouseMove = (event) => {
        if (drawing && boudsSelected === false) {
            const latLng = event.latLng && event.latLng.toJSON(); // Get the clicked LatLng as JSON
            setEndPoint(latLng);
            //console.log('handleMouseMove drawing')

            console.log('handleMouseMove drawing');
        }
    };
    */

    const generateRectangles = (bound) => {
        const rectangles = [];
        const numberOfRectangles = 1;

        const latStep = (bound.north - bound.south) / numberOfRectangles;
        const lngStep = (bound.east - bound.west) / numberOfRectangles;

        let i = 0;
        let j = 0;

        const north = bound.north - i * latStep;
        const south = bound.north - (i + 1) * latStep;
        const west = bound.west + j * lngStep;
        const east = bound.west + (j + 1) * lngStep;

        //Top Ractangle
        const topNorth = north + ((i + 1) * latStep);
        const topSouth = south + (i + 1) * latStep;
        const topWest = west + j * lngStep;
        const topEast = east;
        rectangles.push(
            <>
                <Rectangle
                    //Top Ractangle
                    key={`${i}-${j}`}
                    bounds={{ north: topNorth, south: topSouth, east: topEast, west: topWest }}
                    options={{
                        strokeColor: '#FFFFFF',
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillColor: '#000000',
                        fillOpacity: 0.35,
                    }}
                    onClick={(e) => {
                        console.log('Top ractangle click')
                        //setTotalTopRactangle(totalTopRactangle + 1);
                        var currentBounds = bounds;
                        currentBounds.push({ north: topNorth, south: topSouth, east: topEast, west: topWest });
                        setBounds(currentBounds);
                        console.log(currentBounds)
                        setRefresh(refresh + 1)
                    }}
                />
            </>
        );

        //Bottom Ractangle
        const bottomNorth = north - ((i + 1) * latStep);
        const bottomSouth = south - (i + 1) * latStep;
        const bottomWest = west + j * lngStep;
        const bottomEast = east
        rectangles.push(
            <>
                <Rectangle
                    //Bottom Ractangle
                    key={`${i}-${j}`}
                    bounds={{ north: bottomNorth, south: bottomSouth, east: bottomEast, west: bottomWest }}
                    options={{
                        strokeColor: '#FFFFFF',
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillColor: '#000000',
                        fillOpacity: 0.35,
                    }}
                    onClick={(e) => {
                        console.log('Bottom ractangle click')
                        //setTotalBottomRactangle(totalBottomRactangle + 1);
                        var currentBounds = bounds;
                        currentBounds.push({ north: bottomNorth, south: bottomSouth, east: bottomEast, west: bottomWest });
                        setBounds(currentBounds);
                        console.log(currentBounds)
                        setRefresh(refresh + 1)
                    }}
                />
            </>
        );

        //Right ractangle
        const rightNorth = north;
        const rightSouth = south;
        const rightWest = west + (j + 1) * lngStep;
        const rightEast = east + (j + 1) * lngStep;
        rectangles.push(
            <>
                <Rectangle
                    //Right Ractangle
                    key={`${i}-${j}`}
                    bounds={{ north: rightNorth, south: rightSouth, east: rightEast, west: rightWest }}
                    options={{
                        strokeColor: '#FFFFFF',
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillColor: '#000000',
                        fillOpacity: 0.35,
                    }}

                    onClick={(e) => {
                        console.log('Right ractangle click')
                        //setTotalRightRactangle(totalRightRactangle + 1);
                        var currentBounds = bounds;
                        currentBounds.push({ north: rightNorth, south: rightSouth, east: rightEast, west: rightWest });
                        setBounds(currentBounds);
                        console.log(currentBounds)
                        setRefresh(refresh + 1)
                    }}
                />
            </>
        );


        //Left ractangle
        const leftNorth = north;
        const leftSouth = south;
        const leftWest = west - (j + 1) * lngStep;
        const leftEast = east - (j + 1) * lngStep;
        rectangles.push(
            <>
                <Rectangle
                    //Left Ractangle
                    key={`${i}-${j}`}
                    bounds={{ north: leftNorth, south: leftSouth, east: leftEast, west: leftWest }}
                    options={{
                        strokeColor: '#FFFFFF',
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillColor: '#000000',
                        fillOpacity: 0.35,
                    }}

                    onClick={(e) => {
                        console.log('Left ractangle click');
                        //setTotalLeftRactangle(totalLeftRactangle + 1);
                        var currentBounds = bounds;
                        currentBounds.push({ north: leftNorth, south: leftSouth, east: leftEast, west: leftWest });
                        setBounds(currentBounds);
                        console.log(currentBounds)
                        setRefresh(refresh + 1)
                    }}
                />
            </>
        );

        rectangles.push(
            <>
                <Rectangle
                    //Center Ractangle
                    key={`${i}-${j}`}
                    bounds={{ north, south, east, west }}
                    options={{
                        strokeColor: '#005498',
                        strokeOpacity: 1,
                        strokeWeight: 5,
                        fillColor: '#000000',
                        fillOpacity: 1,
                    }}
                    onClick={(e) => {
                        console.log('Center ractangle click')
                        setRefresh(refresh + 1)
                    }}
                />
            </>
        );

        return rectangles;
    };




    const memoizedMap = useMemo(() => {

        return (
            <GoogleMap
                id='project-location'
                mapContainerStyle={{ height: '100%', width: '100%', borderRadius: '7px', position: 'relative' }}
                mapContainerClassName={'googleMapContainer'}
                zoom={mapZoom}
                center={mapCenter}
                options={{
                    disableDefaultUI: true,
                    zoomControl: (drawing ? false : true),
                    draggable: (drawing ? false : true),
                    mapTypeId: 'satellite',
                }}
                onLoad={handleLoad}
                onClick={handleMapClick}
                //onMouseMove={handleMouseMove}
                //onMouseUp={handleMouseUp}
                onCenterChanged={handleCenterChanged}
                onMouse
            >
                {/*generateRectangles()*/}

                {panelGroups && panelGroups.map((panelGroup, panelGroupKey) => (
                    <OverlayView
                        key={Math.random()}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        position={panelGroup}
                        getPixelPositionOffset={getPixelPositionOffset}
                        on
                    >
                        <div
                            style={{
                                width: '300px', // Example width
                                height: '200px', // Example height
                                //backgroundColor: 'blue', // Example color
                                border: '2px dotted blue',
                                transform: `rotate(${(15)}deg)`,
                            }}
                        >
                            {bounds && bounds.map((bound, key) => (
                                <>
                                    {generateRectangles(bound)}
                                </>
                            ))}

                            <div
                                style={{
                                    width: '50px', // Example width
                                    height: '30px', // Example height
                                    backgroundColor: 'blue', // Example color
                                    //transform: `rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                }}
                            >
                            </div>

                            <div className="buttons text-center" style={{ width: '100px', marginTop: '25px' }}>
                                <i
                                    className="bg-light mx-1 p-1 rounded-circle fa fa-up-down-left-right"
                                    onMouseDown={() => {
                                        console.log('move button onMouseDown')
                                        setDrawing(true);
                                    }}
                                    onMouseUp={()=> {
                                        console.log('move button onMouseUp')
                                        setDrawing(false);
                                    }}                                    
                                ></i>

                                <i className="bg-light mx-1 p-1 rounded-circle fa fa-repeat" style={{ transform: `rotate(90deg)` }}></i>
                                <i
                                    className="bg-light mx-1 p-1 rounded-circle fa fa-rotate"
                                    onClick={(e) => {
                                        console.log('rotate button onClick');
                                        /*
                                        if (rotateing) {
                                            setRotateing(false);
                                        } else {
                                            setRotateing(true);
                                        }
                                        */
                                        //setDrawing(false);
                                        //setRotateing(false);

                                    }}
                                ></i>
                            </div>
                        </div>
                    </OverlayView>
                ))}
            </GoogleMap>
        );
    }, [refresh, width, height, bounds, bounds.length, drawing, boudsSelected]);


    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="card">
                        <div className="card-body p-3" style={{ height: 'calc(100vh - 250px)' }}>
                            {memoizedMap}

                            <div style={{
                                position: 'absolute',
                                top: '25px',
                                left: '25px',
                                zIndex: 1000, // Ensure it's above the map
                                width: '420px'
                            }}>
                                <div className='row' style={{ height: '0px' }}>
                                    <div className='col-9'>
                                        <div className='card'>
                                            <div className='card-header p-2 border'>
                                                <h4 className="m-0">Panels System</h4>
                                            </div>
                                            <div className='card-body p-2'>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">{'Orientation'}</label>
                                                            <select
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    if (e.target.value == 'Portrait') {
                                                                        setWidth(998); //mm
                                                                        setHeight(1690); //mm
                                                                        setWidthMetersToLatitude(((998 * 0.001) / 2) / 111000)
                                                                        setHeightMetersToLatitude(((1690 * 0.001) / 2) / 111000)
                                                                    } else {
                                                                        setWidth(1690); //mm
                                                                        setHeight(998); //mm
                                                                        setWidthMetersToLatitude(((1690 * 0.001) / 2) / 111000)
                                                                        setHeightMetersToLatitude(((998 * 0.001) / 2) / 111000)
                                                                    }
                                                                    setRefresh(refresh + 1)
                                                                    console.log(e.target.value)
                                                                }}
                                                            >
                                                                <option value={"Portrait"}>Portrait</option>
                                                                <option value={"Landscape"}>Landscape</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='col-3'
                                        style={{ height: 'fit-content', width: 'auto' }}
                                    >
                                        <button
                                            type='button'
                                            className='btn btn-primary btn-sm m-0 px-2'
                                            onClick={() => { addPanels() }}
                                        >
                                            <i className='fa fa-plus'></i> <br />
                                            Panels
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default RoofPlannerV3