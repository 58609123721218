import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import IndexTableTickets from "./IndexTableTickets";
import Cookies from 'js-cookie';
import Create from "../../ticket/Create";
import Configurator from '../../ticket/Configurator'; 
function Tickets(props) {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0); 
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Ticket')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTableTickets refresh={refresh} projectId={props.projectId}/>
                        </div>
                    </div>
                </div>
            </div>

            <Create setrefresh={setRefresh}/>
        </>
    );
}

export default Tickets;
