import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import Select from 'react-select';
import SoftSnackbar from "components/SoftSnackbar";
import Timeline from "./Timeline";
import Cookies from 'js-cookie';
import { GoogleMap, useLoadScript, Autocomplete } from '@react-google-maps/api';

import ProjectStatus from '../ProjectStatus';
import Swal from 'sweetalert2';
import dateFormat from "dateformat";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import ProjectPost from "./ProjectPost";
import defaultMapImg from "assets/images/default-map.png";

function CallCenter(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [zipcode, setZipcode] = useState([]);
    const [selectedPlz, setSelectedPlz] = useState({ value: '', label: '---' });
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [selecteCustomertId, setSelecteCustomertId] = useState({ id: '' });
    const [selectedEmployee, setSelectedEmployee] = useState({ id: '' });
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refesh3, setRefesh3] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [create, setCreate] = useState(true);
    const [successSB, setSuccessSB] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);
    const [readOnly, setReadOnly] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [dc, setDC] = useState([]);
    const [ac, setAc] = useState([]);
    const [posts, setPosts] = useState();
    const [viewMode, setViewMode] = useState('overview');
    const [userLead, setUserLead] = useState([]);
    const [mapDisplay, setMapDisplay] = useState(false);

    //const libraries = ["places"]
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ["places"]
    })

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm();

    const onLoad = (autocomplete) => {
        //console.log('autocomplete: ', autocomplete);
        setAutocomplete(autocomplete);
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            //console.log('onPlaceChanged')
            //console.log(autocomplete)

            var place = autocomplete.getPlace();
            //console.log('address_components');
            setValue('plz', '');
            setValue('nr', '');
            setValue('ort', '');
            setValue('street', '');

            if (place?.address_components) {
                for (let [key, value] of Object.entries(place?.address_components)) {
                    if (value?.types) {
                        if (value?.types.indexOf("postal_code") !== -1) {
                            setValue('plz', value?.long_name);
                        } else if (value?.types.indexOf("street_number") !== -1) {
                            setValue('nr', value?.long_name);
                        } else if (value?.types.indexOf("locality") !== -1) {
                            setValue('ort', value?.long_name);
                        } else if (value?.types.indexOf("route") !== -1) {
                            setValue('street', value?.long_name);
                        }
                    }
                }
            }
            setValue('lat', place.geometry.location.lat());
            setValue('lng', place.geometry.location.lng());
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }


    const storno = (e) => {
        if (!data?.data?.id) return;
        e.preventDefault();

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary me-2',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        return swalWithBootstrapButtons.fire({
            text: t("Are you sure?"),
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('Yes, do it!'),
            cancelButtonText: t('Cancel')
        }).then((result) => {
            if (result.isConfirmed) {

                var formData = new FormData();
                formData.id = data?.data?.id;
                formData.stornieren = (data?.data?.stornieren == 0 ? 1 : data?.data?.stornieren == 1 ? 2 : 0);

                callFetch("project/stornieren", "POST", formData, {}).then((res) => {
                    // setSaving(false);
                    setRefesh3(refesh3 + 1);
                    // if (!res.ok) return;
                });

            }
        });
    }


    //plz and ort load
    useEffect(() => {
        if (!data?.data?.id) return;

        setValue('customer_id', data?.data?.customer_id);
    }, [data]);

    useEffect(() => {
        if (!params?.customer) return;

        setValue('date', dateFormat(new Date(), "yyyy-mm-dd"));
        verkauferFeatch();

        for (let [key, e] of Object.entries(customers)) {
            if (e.id == params?.customer) {
                setValue('customer_id', e.id);
                setSelecteCustomertId({
                    id: e?.id,
                    vorname: e?.vorname,
                    name: e?.name,
                    customer_number: e?.customer_number,
                });

                for (let [key, value] of Object.entries(e)) {
                    if (key == 'id') {

                    } else {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    }
                }

                setValue('full_address', e?.street + ' ' + e?.nr);
            }
        }

        //setValue('customer_id', data?.data?.customer_id);
    }, [params?.customer, customers]);


    //Edit Data load
    useEffect(() => {
        flatpickr(".flat-pickr");

        if (JSON.parse(Cookies.get('permissions')).indexOf("PCCU") !== -1) {
            setReadOnly(false);
        } else {
            setReadOnly(true);
        }


        if (props?.update && props.update == true && params?.id) {
            setCreate(false);

            callFetch("projects/" + params.id + "/edit", "GET", []).then((res) => {
                setData(res);
                //console.log(res);
                setUserLead(res.user_lead);
                setEmployee(res.employee);
                setEmployee(res.employee);
                setSelectedEmployee({
                    id: res?.employee?.id,
                    user: {
                        name: res?.employee?.name,
                        id: res?.employee?.id
                    }
                });
                setInvoices(res.invoices);
                setDC(res.dc);
                setAc(res.ac);
                // setQuation(res.quation); 
                setValue('project_id', params.id);
                setPosts(res.project_posts);
                // console.log(res.quation);
                setCustomers(res?.customers);
                for (let [key, value] of Object.entries(res.data)) {
                    if (key == 'date') {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    } else if (key == 'plz') {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    } else if (key == 'customer') {
                        setSelecteCustomertId(value)
                    } else {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    }
                }
                setValue('full_address', res?.data?.street + ' ' + res?.data?.nr);
            });
        }
    }, [params?.id, refesh3]);

    //verkaufer id set
    useEffect(() => {
        if (!data?.data?.verkaufer) return;
        setValue('verkaufer', data?.data?.verkaufer);
    }, [data]);

    //zipcode fetch
    useEffect(() => {
        for (let [zipcode_key, zipcode_value] of Object.entries(zipcode)) {
            if (zipcode_value.label == getValues('plz')) {
                setSelectedPlz(zipcode_value)
            }
        }
    }, [params.id, zipcode, refresh, getValues('plz')]);

    //verkaufer fetch base on date time
    const verkauferFeatch = () => {
        if (params?.id && props?.update && props.update == true) {
            callFetch("projects/" + params?.id + "/edit?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
                setData({
                    employees: res.employees
                })
            });
        } else {

            callFetch("projects/create?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
                setData(res.data);
                setCustomers(res?.data?.customers);
            });
        }
    }

    const onSubmit = (formData) => {
        setSaving(true);
        var loadingElement = document.querySelector('.res-options .btn-primary-save');
        loadingElement.classList.add("btn-secondary");
        loadingElement.innerHTML = t('wird geladen...');

        callFetch(create == true ? "projects" : "projects/" + params?.id, "POST", formData, setError).then((res) => {
            setRefresh2(refresh2 + 1);

            if (res.project_id) {
                setProjectId(res.project_id);
            }

            setTimeout(() => {
                setSaving(false);

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');


                if (!res.ok) return;

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementById("main-content").scrollTop = 0;

                setSuccessSB(true);
                setSubmitSuccess(true);
            }, 2000);
        });
    };

    useEffect(() => {
        if (params?.status == 'new') {
            setSuccessSB(true);
        }
    }, [params?.status]);

    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"


    return submitSuccess && create ? <Navigate to={'/projects/' + projectId} /> :
        <>
            <div className="row mb-5">
                {/* <div className="col-lg-9 mt-lg-0"> */}
                {params?.id > 0 ? '' : <div className="col-sm-9"> <div className="emb-4"> <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">

                    <div className="row g-3">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="example-date-input" className="form-control-label">{t('Date')}</label>

                                        <input className="form-control"
                                            {...register("date", {
                                                required: true,
                                                disabled: readOnly,
                                                onChange: (e) => {
                                                    verkauferFeatch();
                                                }
                                            })}
                                            required
                                            type="date" id="example-date-input" />
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            {t('Time')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("time", {
                                                required: true,
                                                disabled: readOnly,
                                                onChange: (e) => {
                                                    verkauferFeatch();
                                                }
                                            })}
                                            required
                                        >
                                            <option value="08:00">08:00 - 10:00</option>
                                            <option value="10:00">10:00 - 12:00</option>
                                            <option value="12:00">12:00 - 14:00</option>
                                            <option value="14:00">14:00 - 16:00</option>
                                            <option value="16:00">16:00 - 18:00</option>
                                            <option value="18:00">18:00 - 20:00</option>
                                            <option value="20:00">20:00 - 22:00</option>
                                            <option value="22:00">22:00 - 24:00</option>
                                            <option value="00:00">00:00 - 02:00</option>
                                            <option value="02:00">02:00 - 04:00</option>
                                            <option value="04:00">04:00 - 06:00</option>
                                            <option value="06:00">06:00 - 08:00</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            {t('Verkaufer')} *
                                        </label>
                                        <br />
                                        <Select
                                            placeholder={t('Select')}
                                            options={data?.employees ? data?.employees : []}
                                            getOptionLabel={(option) => ((option?.user?.name ? option?.user?.name : ''))}
                                            getOptionValue={(option) => option?.user?.id}
                                            value={selectedEmployee}
                                            onChange={(e) => {
                                                setValue('verkaufer', e.user.id);
                                                setSelectedEmployee({
                                                    id: e?.user.id,
                                                    user: {
                                                        name: e?.user.name,
                                                    },
                                                });
                                                clearErrors();
                                            }}

                                            required={true}
                                            styles={{
                                                fontSize: '0.875rem',
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    fontSize: '0.875rem',
                                                }),
                                            }}
                                        />
                                        <div className="invalid-feedback d-block">{errors.verkaufer ? t(errors.verkaufer.message) : ''}</div>
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            {t('Beschreibung')}
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="5"
                                            placeholder={t('eg. 1234 My street, City')}
                                            {...register("description", {
                                                disabled: readOnly,
                                            })}></textarea>
                                        <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    {t('Kunde')}
                                                </label>
                                                <br />

                                                <Select
                                                    placeholder={t('Select')}
                                                    options={customers ? customers : {}}
                                                    getOptionLabel={(option) => ((option.customer_number ? option.customer_number : '') + (option.vorname ? ' - ' + option.vorname : '') + ' ' + (option?.name ? option?.name : ''))}
                                                    getOptionValue={(option) => option.id}
                                                    value={selecteCustomertId}

                                                    onChange={(e) => {
                                                        setValue('customer_id', e.id);
                                                        setSelecteCustomertId({
                                                            id: e?.id,
                                                            vorname: e?.vorname,
                                                            name: e?.name,
                                                            customer_number: e?.customer_number,
                                                        });

                                                        for (let [key, value] of Object.entries(e)) {
                                                            if (key == 'id') {

                                                            } else {
                                                                setValue(key, (value == null || value == 'null' ? "" : value));
                                                            }
                                                        }

                                                        setValue('full_address', e?.street + ' ' + e?.nr);
                                                    }}
                                                    required
                                                    styles={{
                                                        fontSize: '0.875rem',
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            fontSize: '0.875rem',
                                                        }),
                                                    }}
                                                />
                                                <div className="invalid-feedback">{errors.customer_id && errors.customer_id.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6"></div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>{t('Anrede')} *</label>
                                                <select
                                                    className="form-control"
                                                    {...register("anrede", {
                                                        required: true,
                                                        disabled: readOnly,
                                                    })}
                                                    required>
                                                    <option value="">--</option>
                                                    <option value="Herr">{t('Herr')}</option>
                                                    <option value="Frau">{t('Frau')}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.anrede && errors.anrede.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>{t('Vorname')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("vorname", {
                                                        required: true,
                                                        disabled: readOnly,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.vorname && errors.vorname.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>{t('Name')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("name", {
                                                        required: true,
                                                        disabled: readOnly,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-9">
                                            <Autocomplete
                                                options={{
                                                    componentRestrictions: { country: "de" },
                                                    //fields: ["address_components", "geometry", "icon", "name"],
                                                    //types: ["establishment"]
                                                }}
                                                onLoad={onLoad}
                                                onPlaceChanged={onPlaceChanged}
                                            >
                                                <div className="form-group">
                                                    <label>{t('Straße')} *</label>
                                                    <input
                                                        type="text"
                                                        placeholder={t('')}
                                                        className="form-control"
                                                        //defaultValue={getValues('street') ? getValues('street') + ' ' + getValues('nr') : ''}
                                                        {...register("full_address", {
                                                            required: false,
                                                            disabled: readOnly,
                                                        })}

                                                    />
                                                    <div className="invalid-feedback d-block">{errors.street ? errors.street.message : (errors.nr ? t('Please add a street nr.') : '')}</div>
                                                </div>
                                            </Autocomplete>
                                            <div className="form-group d-none">
                                                <label>{t('Straße')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("street", {
                                                        required: true,
                                                        disabled: readOnly,
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>{t('Nr')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("nr", {
                                                        required: false,
                                                        disabled: readOnly,
                                                    })}
                                                    readOnly
                                                />
                                                <div className="invalid-feedback">{errors.nr && errors.nr.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('PLZ')} *</label>

                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("plz", {
                                                        required: false,
                                                        disabled: readOnly,
                                                    })}
                                                    readOnly
                                                />
                                                <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Ort')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("ort", {
                                                        required: false,
                                                        disabled: readOnly,
                                                    })}
                                                    readOnly
                                                />
                                                <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Firma')}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("firma", {
                                                        disabled: readOnly,
                                                    })} />
                                                <div className="invalid-feedback">{errors.firma && errors.firma.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('E-mail Adresse')}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("email", {
                                                        required: false,
                                                        disabled: readOnly,
                                                        onChange: (e) => {
                                                            //setCustomEmail(e.target.value);
                                                        }
                                                    })}
                                                />
                                                <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                            </div>
                                        </div>

                                        {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                            <>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Telefonnummer')}</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('')}
                                                            {...register("telefonnummer", {
                                                                required: false,
                                                                disabled: readOnly,
                                                            })}
                                                        />
                                                        <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t('Mobilnummer')}</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('')}
                                                            {...register("mobilnummer", {
                                                                required: false,
                                                                disabled: readOnly,
                                                            })}
                                                        />
                                                        <div className="invalid-feedback">{errors.mobilnummer && errors.mobilnummer.message}</div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : ''}

                                    </div>
                                </div>
                            </div>


                            <div className="card mt-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Entscheidungstrager')}</label>
                                                <input
                                                    type="text"
                                                    placeholder="Entscheidungstrager"
                                                    className=" form-control"
                                                    {...register("entscheidungstrager", {
                                                        required: false,
                                                        disabled: readOnly,
                                                    })}
                                                />
                                                <div className="invalid-feedback">{errors.entscheidungstrager && errors.entscheidungstrager.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Erfahrung Mit PV')}</label>
                                                <select
                                                    className="form-control"
                                                    {...register("erfahrung_mit_pv", {
                                                        disabled: readOnly,
                                                    })}
                                                >
                                                    <option value="">--</option>
                                                    <option value="Yes">{t('Yes')}</option>
                                                    <option value="No">{t('No')}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.erfahrung_mit_pv && errors.erfahrung_mit_pv.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Gesamtverbrauch Kwh')}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('Gesamtverbrauch Kwh')}
                                                    {...register("gesamtverbrauch_kwh", {
                                                        disabled: readOnly,
                                                    })}

                                                />
                                                <div className="invalid-feedback">{errors.gesamtverbrauch_kwh && errors.gesamtverbrauch_kwh.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Monatlicher Abschlag In Euro')}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('Monatlicher Abschlag In Euro')}
                                                    {...register("monatlicher", {
                                                        disabled: readOnly,
                                                    })}

                                                />
                                                <div className="invalid-feedback">{errors.monatlicher && errors.monatlicher.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Stromabrechung Vorhanden')}</label>
                                                <select className="form-control"
                                                    {...register("stromabrechung_vorhanden", {
                                                        disabled: readOnly,
                                                    })}
                                                >
                                                    <option>--</option>
                                                    <option value="Yes" >{t('Yes')}</option>
                                                    <option value="No" >{t('No')}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.stromabrechung_vorhanden && errors.stromabrechung_vorhanden.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Dachausrichtung')}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("dachausrichtung", {
                                                        disabled: readOnly,
                                                    })}

                                                />
                                                <div className="invalid-feedback">{errors.dachausrichtung && errors.dachausrichtung.message}</div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Dacheindeckung')}</label>
                                                <select
                                                    className="form-control"
                                                    {...register("ziegelart", {
                                                        required: false,
                                                        disabled: readOnly,
                                                    })}
                                                >
                                                    <option value="">--</option>
                                                    <option value="Sonstiges">{t('Sonstiges')}</option>
                                                    <option value="Ziegel">{t('Ziegel')}</option>
                                                    <option value="Blech">{t('Blech')}</option>
                                                    <option value="Kies">{t('Kies')}</option>
                                                    <option value="Schiefer">{t('Schiefer')}</option>
                                                    <option value="Schindel">{t('Schindel')}</option>
                                                    <option value="Betumen">{t('Betumen')}</option>
                                                    <option value="Gründach">{t('Gründach')}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.dacheindeckung && errors.dacheindeckung.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('E-Mobilitat')}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('E-Mobilitat')}
                                                    {...register("e_mobilitat", {
                                                        disabled: readOnly,
                                                    })}

                                                />
                                                <div className="invalid-feedback">{errors.e_mobilitat && errors.e_mobilitat.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Zeitraum (max.)')}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('Zeitraum (max.)')}
                                                    {...register("zeitraum_max", {
                                                        disabled: readOnly,
                                                    })}

                                                />
                                                <div className="invalid-feedback">{errors.zeitraum_max && errors.zeitraum_max.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('Internet')}</label>
                                                <select
                                                    className="form-control"
                                                    {...register("internet", {
                                                        disabled: readOnly,
                                                    })}
                                                >
                                                    <option value="">--</option>
                                                    <option value="Yes">{t('Yes')}</option>
                                                    <option value="No">{t('No')}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.internet && errors.internet.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {JSON.parse(Cookies.get('permissions')).indexOf("PCCU") !== -1 ? (
                        <div className="row g-3">
                            <div className="col-12 my-4">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary" id="callSubmitBtn">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="button" className="btn btn-secondary" disabled>
                                        {t('wird geladen...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : <></>}
                </form> </div> </div>}
                {params.id > 0 && (
                    <div className="row">
                        {viewMode != 'overview' ? <div className="col-lg-9 mt-lg-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <a onClick={() => setViewMode('overview')} style={{ display: 'inline-block', position: 'relative', top: '-12px', borderBottom: '1px solid rgb(0, 84, 152)', fontSize: '15px', color: 'rgb(0, 84, 152)', margin: '0px', padding: '0px', fontWeight: '700' }}><i className="fa-solid fa-arrow-left-long"></i> {t('Zurück')}</a>
                                    <div className="emb-4">
                                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                            {create == false ? (
                                                <input type="hidden" defaultValue="PUT" {...register("_method")} />
                                            ) : <></>}
                                            <div className="row g-3">
                                                <div className="col-md-4">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="form-group">
                                                                <label htmlFor="example-date-input" className="form-control-label">{t('Date')}</label>

                                                                <input className="form-control"
                                                                    {...register("date", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => {
                                                                            verkauferFeatch();
                                                                        }
                                                                    })}
                                                                    required
                                                                    type="date" id="example-date-input" />
                                                            </div>

                                                            <div className="form-group">
                                                                <label>
                                                                    {t('Time')} *
                                                                </label>
                                                                <br />
                                                                <select
                                                                    className="form-control"
                                                                    {...register("time", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => {
                                                                            verkauferFeatch();
                                                                        }
                                                                    })}
                                                                    required
                                                                >
                                                                    <option value="08:00">08:00 - 10:00</option>
                                                                    <option value="10:00">10:00 - 12:00</option>
                                                                    <option value="12:00">12:00 - 14:00</option>
                                                                    <option value="14:00">14:00 - 16:00</option>
                                                                    <option value="16:00">16:00 - 18:00</option>
                                                                    <option value="18:00">18:00 - 20:00</option>
                                                                    <option value="20:00">20:00 - 22:00</option>
                                                                    <option value="22:00">22:00 - 24:00</option>
                                                                    <option value="00:00">00:00 - 02:00</option>
                                                                    <option value="02:00">02:00 - 04:00</option>
                                                                    <option value="04:00">04:00 - 06:00</option>
                                                                    <option value="06:00">06:00 - 08:00</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label>
                                                                    {t('Verkaufer')} *
                                                                </label>
                                                                <br />
                                                                <Select
                                                                    placeholder={t('Select')}
                                                                    options={data.employees ? data.employees : {}}
                                                                    getOptionLabel={(option) => ((option.user.name ? option.user.name : ''))}
                                                                    getOptionValue={(option) => option.user.id}
                                                                    value={selectedEmployee}
                                                                    onChange={(e) => {
                                                                        setValue('verkaufer', e.user.id);
                                                                        setSelectedEmployee({
                                                                            id: e?.user.id,
                                                                            user: {
                                                                                name: e?.user.name,
                                                                            },
                                                                        });
                                                                        clearErrors();
                                                                    }}

                                                                    required={true}
                                                                    styles={{
                                                                        fontSize: '0.875rem',
                                                                        control: (baseStyles, state) => ({
                                                                            ...baseStyles,
                                                                            fontSize: '0.875rem',
                                                                        }),
                                                                    }}
                                                                />

                                                                <div className="invalid-feedback d-block">{errors.verkaufer ? t(errors.verkaufer.message) : ''}</div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label>
                                                                    {t('Beschreibung')}
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    rows="5"
                                                                    placeholder={t('eg. 1234 My street, City')}
                                                                    {...register("description", {
                                                                        disabled: readOnly,
                                                                    })}></textarea>
                                                                <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-8">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            {t('Kunde')}
                                                                        </label>
                                                                        <br />

                                                                        <Select
                                                                            placeholder={t('Select')}
                                                                            options={customers ? customers : {}}
                                                                            getOptionLabel={(option) => ((option.customer_number ? option.customer_number : '') + (option.vorname ? ' - ' + option.vorname : '') + ' ' + (option?.name ? option?.name : ''))}
                                                                            getOptionValue={(option) => option.id}
                                                                            value={selecteCustomertId}

                                                                            onChange={(e) => {
                                                                                setValue('customer_id', e.id);
                                                                                setSelecteCustomertId({
                                                                                    id: e?.id,
                                                                                    vorname: e?.vorname,
                                                                                    name: e?.name,
                                                                                    customer_number: e?.customer_number,
                                                                                });

                                                                                for (let [key, value] of Object.entries(e)) {
                                                                                    if (key == 'id') {

                                                                                    } else {
                                                                                        setValue(key, (value == null || value == 'null' ? "" : value));
                                                                                    }
                                                                                }

                                                                                setValue('full_address', e?.street + ' ' + e?.nr);
                                                                            }}
                                                                            required
                                                                            styles={{
                                                                                fontSize: '0.875rem',
                                                                                control: (baseStyles, state) => ({
                                                                                    ...baseStyles,
                                                                                    fontSize: '0.875rem',
                                                                                }),
                                                                            }}
                                                                        />
                                                                        <div className="invalid-feedback">{errors.customer_id && errors.customer_id.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6"></div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <label>{t('Anrede')} *</label>
                                                                        <select
                                                                            className="form-control"
                                                                            {...register("anrede", {
                                                                                required: true,
                                                                                disabled: readOnly,
                                                                            })}
                                                                            required>
                                                                            <option value="">--</option>
                                                                            <option value="Herr">{t('Herr')}</option>
                                                                            <option value="Frau">{t('Frau')}</option>
                                                                        </select>
                                                                        <div className="invalid-feedback">{errors.anrede && errors.anrede.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5">
                                                                    <div className="form-group">
                                                                        <label>{t('Vorname')} *</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("vorname", {
                                                                                required: true,
                                                                                disabled: readOnly,
                                                                            })}
                                                                            required
                                                                        />
                                                                        <div className="invalid-feedback">{errors.vorname && errors.vorname.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5">
                                                                    <div className="form-group">
                                                                        <label>{t('Name')} *</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("name", {
                                                                                required: true,
                                                                                disabled: readOnly,
                                                                            })}
                                                                            required
                                                                        />
                                                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-9">
                                                                    <Autocomplete
                                                                        options={{
                                                                            componentRestrictions: { country: "de" },
                                                                            //fields: ["address_components", "geometry", "icon", "name"],
                                                                            //types: ["establishment"]
                                                                        }}
                                                                        onLoad={onLoad}
                                                                        onPlaceChanged={onPlaceChanged}
                                                                    >
                                                                        <div className="form-group">
                                                                            <label>{t('Straße')} *</label>
                                                                            <input
                                                                                type="text"
                                                                                placeholder={t('')}
                                                                                className="form-control"
                                                                                //defaultValue={getValues('street') ? getValues('street') + ' ' + getValues('nr') : ''}
                                                                                {...register("full_address", {
                                                                                    required: false,
                                                                                    disabled: readOnly,
                                                                                })}

                                                                            />
                                                                            <div className="invalid-feedback d-block">{errors.street ? errors.street.message : (errors.nr ? t('Please add a street nr.') : '')}</div>
                                                                        </div>
                                                                    </Autocomplete>
                                                                    <div className="form-group d-none">
                                                                        <label>{t('Straße')} *</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("street", {
                                                                                required: true,
                                                                                disabled: readOnly,
                                                                            })}
                                                                            required
                                                                        />
                                                                        <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label>{t('Nr')} *</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("nr", {
                                                                                required: false,
                                                                                disabled: readOnly,
                                                                            })}
                                                                            readOnly
                                                                        />
                                                                        <div className="invalid-feedback">{errors.nr && errors.nr.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('PLZ')} *</label>

                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("plz", {
                                                                                required: false,
                                                                                disabled: readOnly,
                                                                            })}
                                                                            readOnly
                                                                        />
                                                                        <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Ort')} *</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("ort", {
                                                                                required: false,
                                                                                disabled: readOnly,
                                                                            })}
                                                                            readOnly
                                                                        />
                                                                        <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Firma')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("firma", {
                                                                                disabled: readOnly,
                                                                            })} />
                                                                        <div className="invalid-feedback">{errors.firma && errors.firma.message}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('E-mail Adresse')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("email", {
                                                                                required: false,
                                                                                disabled: readOnly,
                                                                                onChange: (e) => {
                                                                                    //setCustomEmail(e.target.value);
                                                                                }
                                                                            })}
                                                                        />
                                                                        <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                                                    </div>
                                                                </div>

                                                                {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                                                    <>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label>{t('Telefonnummer')}</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder={t('')}
                                                                                    {...register("telefonnummer", {
                                                                                        required: false,
                                                                                        disabled: readOnly,
                                                                                    })}
                                                                                />
                                                                                <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label>{t('Mobilnummer')}</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder={t('')}
                                                                                    {...register("mobilnummer", {
                                                                                        required: false,
                                                                                        disabled: readOnly,
                                                                                    })}
                                                                                />
                                                                                <div className="invalid-feedback">{errors.mobilnummer && errors.mobilnummer.message}</div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : ''}

                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="card mt-3">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Entscheidungstrager')}</label>
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Entscheidungstrager"
                                                                            className=" form-control"
                                                                            {...register("entscheidungstrager", {
                                                                                required: false,
                                                                                disabled: readOnly,
                                                                            })}
                                                                        />
                                                                        <div className="invalid-feedback">{errors.entscheidungstrager && errors.entscheidungstrager.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Erfahrung Mit PV')}</label>
                                                                        <select
                                                                            className="form-control"
                                                                            {...register("erfahrung_mit_pv", {
                                                                                disabled: readOnly,
                                                                            })}
                                                                        >
                                                                            <option value="">--</option>
                                                                            <option value="Yes">{t('Yes')}</option>
                                                                            <option value="No">{t('No')}</option>
                                                                        </select>
                                                                        <div className="invalid-feedback">{errors.erfahrung_mit_pv && errors.erfahrung_mit_pv.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Gesamtverbrauch Kwh')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('Gesamtverbrauch Kwh')}
                                                                            {...register("gesamtverbrauch_kwh", {
                                                                                disabled: readOnly,
                                                                            })}

                                                                        />
                                                                        <div className="invalid-feedback">{errors.gesamtverbrauch_kwh && errors.gesamtverbrauch_kwh.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Monatlicher Abschlag In Euro')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('Monatlicher Abschlag In Euro')}
                                                                            {...register("monatlicher", {
                                                                                disabled: readOnly,
                                                                            })}

                                                                        />
                                                                        <div className="invalid-feedback">{errors.monatlicher && errors.monatlicher.message}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Stromabrechung Vorhanden')}</label>
                                                                        <select className="form-control"
                                                                            {...register("stromabrechung_vorhanden", {
                                                                                disabled: readOnly,
                                                                            })}
                                                                        >
                                                                            <option>--</option>
                                                                            <option value="Yes" >{t('Yes')}</option>
                                                                            <option value="No" >{t('No')}</option>
                                                                        </select>
                                                                        <div className="invalid-feedback">{errors.stromabrechung_vorhanden && errors.stromabrechung_vorhanden.message}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Dachausrichtung')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("dachausrichtung", {
                                                                                disabled: readOnly,
                                                                            })}

                                                                        />
                                                                        <div className="invalid-feedback">{errors.dachausrichtung && errors.dachausrichtung.message}</div>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Dacheindeckung')}</label>
                                                                        <select
                                                                            className="form-control"
                                                                            {...register("ziegelart", {
                                                                                required: false,
                                                                                disabled: readOnly,
                                                                            })}
                                                                        >
                                                                            <option value="">--</option>
                                                                            <option value="Sonstiges">{t('Sonstiges')}</option>
                                                                            <option value="Ziegel">{t('Ziegel')}</option>
                                                                            <option value="Blech">{t('Blech')}</option>
                                                                            <option value="Kies">{t('Kies')}</option>
                                                                            <option value="Schiefer">{t('Schiefer')}</option>
                                                                            <option value="Schindel">{t('Schindel')}</option>
                                                                            <option value="Betumen">{t('Betumen')}</option>
                                                                            <option value="Gründach">{t('Gründach')}</option>
                                                                        </select>
                                                                        <div className="invalid-feedback">{errors.dacheindeckung && errors.dacheindeckung.message}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('E-Mobilitat')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('E-Mobilitat')}
                                                                            {...register("e_mobilitat", {
                                                                                disabled: readOnly,
                                                                            })}

                                                                        />
                                                                        <div className="invalid-feedback">{errors.e_mobilitat && errors.e_mobilitat.message}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Zeitraum (max.)')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('Zeitraum (max.)')}
                                                                            {...register("zeitraum_max", {
                                                                                disabled: readOnly,
                                                                            })}

                                                                        />
                                                                        <div className="invalid-feedback">{errors.zeitraum_max && errors.zeitraum_max.message}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Internet')}</label>
                                                                        <select
                                                                            className="form-control"
                                                                            {...register("internet", {
                                                                                disabled: readOnly,
                                                                            })}
                                                                        >
                                                                            <option value="">--</option>
                                                                            <option value="Yes">{t('Yes')}</option>
                                                                            <option value="No">{t('No')}</option>
                                                                        </select>
                                                                        <div className="invalid-feedback">{errors.internet && errors.internet.message}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {JSON.parse(Cookies.get('permissions')).indexOf("PCCU") !== -1 ? (
                                                <div className="row g-3">
                                                    <div className="col-12 my-4">
                                                        {!saving && (
                                                            <button type="submit" className="btn btn-primary" id="callSubmitBtn">
                                                                {t('Save')}
                                                            </button>
                                                        )}
                                                        {saving && (
                                                            <button type="button" className="btn btn-secondary" disabled>
                                                                {t('wird geladen...')}
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : <></>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> : <>  <div className="col">
                            <div className="card">
                                <div className="card-body" style={{ padding: '15px 15px 38px 15px' }}>
                                    <SoftTypography variant="button" color="text" fontWeight="medium" style={{ color: 'rgb(183, 183, 183)', fontWeight: '700' }}>Verkäufer</SoftTypography>
                                    <div className="mb-3"></div>
                                    <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
                                        <SoftBox display="flex" mr={2}>
                                            <SoftAvatar src={'/assets/img/placeholder.png'} alt={'Rakib Rizan'} size="md" variant="rounded" />
                                            <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
                                                    {employee?.name}
                                                </SoftTypography>

                                                <SoftTypography variant="caption" color="text">
                                                    {employee?.roles ? employee?.roles[0]?.name : ''}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </SoftBox>
                                </div>
                            </div>

                            <div className="card mt-3" style={{ padding: '5px' }}>
                                <div className="card-body callcenter-area" style={{ padding: '10px', height: '600px', overflowY: 'auto' }}>
                                    <button onClick={() => setViewMode('edit')} className="btn btn-light pull-right btn-sm" style={{ padding: '0px', width: '30px', height: '30px', marginRight: '15px' }}>
                                        <i className="fa fa-pencil"></i>
                                    </button>
                                    <SoftTypography variant="button" color="text" fontWeight="medium" style={{ color: 'rgb(183, 183, 183)', fontWeight: '700' }}>Lead Owner</SoftTypography>
                                    <div className="mb-2"></div>

                                    <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
                                        <SoftBox display="flex" mr={2}>
                                            <SoftAvatar src={'/assets/img/placeholder.png'} alt={'Rakib Rizan'} size="md" variant="rounded" />
                                            <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
                                                <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
                                                    {userLead?.name}
                                                </SoftTypography>

                                                <SoftTypography variant="caption" color="text">
                                                    {userLead?.roles ? userLead?.roles[0]?.name : ''}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </SoftBox>

                                    <SoftTypography mt={5} variant="h6" fontSize="14px" fontWeight="bold">Termin</SoftTypography>

                                    <SoftTypography mt={3} variant="h6" fontWeight="bold" fontSize="14px">{dateFormat(data?.data?.date, "dd.mm.yyyy")}</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular">{data?.data?.time} Uhr</SoftTypography>

                                    <SoftTypography mt={5} variant="h6" fontWeight="bold" fontSize="14px">Beschreibung</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.description}
                                    </SoftTypography>

                                    <SoftTypography mt={5} variant="h6" fontWeight="bold" fontSize="14px">Entscheidungstrager</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.entscheidungstrager}
                                    </SoftTypography>
                                    <SoftTypography mt={3} variant="h6" fontWeight="bold" fontSize="14px">Gesamtverbrauch Kwh</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.gesamtverbrauch_kwh}
                                    </SoftTypography>
                                    <SoftTypography mt={3} variant="h6" fontWeight="bold" fontSize="14px">Stromabrechung Vorhanden</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.stromabrechung_vorhanden}
                                    </SoftTypography>
                                    <SoftTypography mt={3} variant="h6" fontWeight="bold" fontSize="14px">Dacheindeckung</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.dachausrichtung}
                                    </SoftTypography>
                                    <SoftTypography mt={3} variant="h6" fontWeight="bold" fontSize="14px">Zeitraum (max.)</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.zeitraum_max}
                                    </SoftTypography>
                                    <SoftTypography mt={3} variant="h6" fontWeight="bold" fontSize="14px">Erfahrung Mit PV:</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.erfahrung_mit_pv}
                                    </SoftTypography>
                                    <SoftTypography mt={3} variant="h6" fontWeight="bold" fontSize="14px">Monatlicher Abschlag In Euro:</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.monatlicher}
                                    </SoftTypography>
                                    <SoftTypography mt={3} variant="h6" fontWeight="bold" fontSize="14px">Dachausrichtung:</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.dachausrichtung}
                                    </SoftTypography>
                                    <SoftTypography mt={3} variant="h6" fontWeight="bold" fontSize="14px">E-Mobilitat:</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.e_mobilitat}
                                    </SoftTypography>
                                    <SoftTypography mt={3} variant="h6" fontWeight="bold" fontSize="14px">Internet:</SoftTypography>
                                    <SoftTypography mt={2} variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5' }}>
                                        {data?.data?.internet}
                                    </SoftTypography>
                                </div>
                            </div>

                        </div>
                            <div className="col-sm-4" style={{ padding: '0px' }}>
                                <div className="card">
                                    <div className="card-body" style={{ padding: '15px' }}>
                                        <button onClick={() => setViewMode('edit')} className="btn btn-light pull-right btn-sm" style={{ padding: '0px', width: '30px', height: '30px', marginRight: '15px' }}>
                                            <i className="fa fa-pencil"></i>
                                        </button>
                                        <SoftTypography mb={3} variant="button" color="text" fontWeight="medium" style={{ color: 'rgb(183, 183, 183)', fontWeight: '700' }}>Kunde</SoftTypography>
                                        <div className="mb-2"></div>
                                        <SoftBox>
                                            <div className="row">
                                                <div className="col-sm-2"><SoftTypography variant="h6" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}>Name:</SoftTypography></div>
                                                <div className="col-sm-10"><SoftTypography variant="caption" style={{ position: 'absolute' }} fontSize="12px" fontSizeXS color="text" fontWeight="regular"> {getValues('vorname')} {getValues('name')}</SoftTypography> </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-2"><SoftTypography variant="h6" fontSize="12px" style={{ fontWeight: '700' }}>Phone:</SoftTypography></div>
                                                <div className="col-sm-10"><SoftTypography variant="caption" style={{ position: 'absolute', marginTop: '3px' }} fontSize="12px" color="text" fontWeight="regular"> {getValues('telefonnummer')} </SoftTypography> </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-2"><SoftTypography variant="h6" fontSize="12px" style={{ fontWeight: '700' }}>E-mail:</SoftTypography></div>
                                                <div className="col-sm-10"><SoftTypography variant="caption" style={{ position: 'absolute', marginTop: '3px' }} fontSize="12px" color="text" fontWeight="regular"> {getValues('email')} </SoftTypography> </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-2"><SoftTypography variant="h6" fontSize="12px" style={{ fontWeight: '700' }}>Address:</SoftTypography></div>
                                                <div className="col-sm-10"><SoftTypography variant="caption" style={{ position: 'absolute', marginTop: '3px' }} fontSize="12px" color="text" fontWeight="regular">{getValues('street')} {getValues('nr')}, {getValues('plz')}, {getValues('ort')} </SoftTypography> </div>
                                            </div>
                                        </SoftBox>
                                    </div>
                                </div>

                                <div className="card mt-3">
                                    <div className="card-body callcenter" style={{ padding: '15px' }}>
                                        <ul className="nav nav-tabs nav-underline" id="myTab" role="tablist">

                                            {JSON.parse(Cookies.get('permissions')).indexOf("PDU") !== -1 ? (
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" style={{ color: '#0048B1', fontSize: '0.75rem' }}>Angebot</button>
                                                </li>
                                            ) : <></>}

                                            {JSON.parse(Cookies.get('permissions')).indexOf("OPR") !== -1 ? (
                                                <>
                                                    <li className={"nav-item "} role="presentation">
                                                        <button className={"nav-link " + (JSON.parse(Cookies.get('permissions')).indexOf("PDU") === -1 ? 'active' : '')} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.75rem' }}>Auftrag</button>
                                                    </li>
                                                </>
                                            ) : <></>}

                                            {JSON.parse(Cookies.get('permissions')).indexOf("PAR") !== -1 ? (
                                                <li className="nav-item" role="presentation">
                                                    <button className={"nav-link " + (JSON.parse(Cookies.get('permissions')).indexOf("OPR") === -1 ? 'active' : '')} id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.75rem' }}>Rechnung</button>
                                                </li>
                                            ) : <></>}

                                            {JSON.parse(Cookies.get('permissions')).indexOf("POPR") !== -1 ? (
                                                <li className="nav-item" role="presentation">
                                                    <button className={"nav-link " + (JSON.parse(Cookies.get('permissions')).indexOf("PAR") === -1 ? 'active' : '')} id="abnahme-tab" data-bs-toggle="tab" data-bs-target="#abnahme-tab-pane" type="button" role="tab" aria-controls="abnahme-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.75rem' }}>Abnahme</button>
                                                </li>
                                            ) : <></>}
                                        </ul>
                                        <div className="tab-content" id="myTabContent">

                                            {JSON.parse(Cookies.get('permissions')).indexOf("PDU") !== -1 ? (
                                                <div className="tab-pane fade show active p-3" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                                    {data?.data?.quation_id && (
                                                        <div className="row">
                                                            <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'quation/' + data?.data?.quation_id}>
                                                                <div className="item" style={{ border: '1px solid #ddd', backgroundColor: '#F9F9F9', borderRadius: '3px', width: '118px', padding: '0px 8px', height: '32px' }}>
                                                                    <div className="item-icon">
                                                                        <div>
                                                                            <svg style={{ height: '30px', width: '15px' }} viewBox="0 0 56 54" xmlns="http://www.w3.org/2000/svg" width="2500" height="2411"><path d="M9.9 0h36.2C51.6 0 56 4.4 56 9.9v34.2c0 5.5-4.4 9.9-9.9 9.9H9.9C4.4 54 0 49.6 0 44.1V9.9C0 4.4 4.4 0 9.9 0z" fill="#718096" /><path d="M45 31.2c-2.6-2.7-9.7-1.6-11.4-1.4-2.5-2.4-4.2-5.3-4.8-6.3.9-2.7 1.5-5.4 1.6-8.3 0-2.5-1-5.2-3.8-5.2-1 0-1.9.6-2.4 1.4-1.2 2.1-.7 6.3 1.2 10.6-1.1 3.1-2.1 6.1-4.9 11.4-2.9 1.2-9 4-9.5 7-.2.9.1 1.8.8 2.5.7.6 1.6.9 2.5.9 3.7 0 7.3-5.1 9.8-9.4 2.1-.7 5.4-1.7 8.7-2.3 3.9 3.4 7.3 3.9 9.1 3.9 2.4 0 3.3-1 3.6-1.9.5-1 .2-2.1-.5-2.9zm-2.5 1.7c-.1.7-1 1.4-2.6 1-1.9-.5-3.6-1.4-5.1-2.6 1.3-.2 4.2-.5 6.3-.1.8.2 1.6.7 1.4 1.7zM25.8 12.3c.2-.3.5-.5.8-.5.9 0 1.1 1.1 1.1 2-.1 2.1-.5 4.2-1.2 6.2-1.5-4-1.2-6.8-.7-7.7zm-.2 19.4c.8-1.6 1.9-4.4 2.3-5.6.9 1.5 2.4 3.3 3.2 4.1 0 .1-3.1.7-5.5 1.5zm-5.9 4c-2.3 3.8-4.7 6.2-6 6.2-.2 0-.4-.1-.6-.2-.3-.2-.4-.5-.3-.9.3-1.4 2.9-3.3 6.9-5.1z" fill="#fff" /></svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="item-text" style={{ paddingLeft: '5px' }}>
                                                                        <SoftTypography variant="h6" fontWeight="medium" style={{ color: '#718096', position: 'relative', top: '3px', fontSize: '8px' }}>ANGEBOT #{data?.data?.quation_id}</SoftTypography>
                                                                        <SoftTypography variant="caption" color="text" style={{ position: 'relative', top: '-7px' }} fontSize="8px" fontWeight="regular">erstellt {dateFormat(data?.data?.quation_created_at, "dd.mm.yyyy")}</SoftTypography>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : <></>}


                                            {JSON.parse(Cookies.get('permissions')).indexOf("OPR") !== -1 ? (
                                                <div className={(JSON.parse(Cookies.get('permissions')).indexOf("PDU") === -1 ? 'tab-pane fade show active p-3' : 'tab-pane fade p-3')} id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                                    {data?.data?.order_id && (
                                                        <div className="row">
                                                            <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'order/' + data?.data?.order_id}>
                                                                <div className="item" style={{ border: '1px solid #ddd', backgroundColor: '#F9F9F9', borderRadius: '3px', width: '118px', padding: '0px 8px', height: '32px' }}>
                                                                    <div className="item-icon">
                                                                        <div>
                                                                            <svg style={{ height: '30px', width: '15px' }} viewBox="0 0 56 54" xmlns="http://www.w3.org/2000/svg" width="2500" height="2411"><path d="M9.9 0h36.2C51.6 0 56 4.4 56 9.9v34.2c0 5.5-4.4 9.9-9.9 9.9H9.9C4.4 54 0 49.6 0 44.1V9.9C0 4.4 4.4 0 9.9 0z" fill="#718096" /><path d="M45 31.2c-2.6-2.7-9.7-1.6-11.4-1.4-2.5-2.4-4.2-5.3-4.8-6.3.9-2.7 1.5-5.4 1.6-8.3 0-2.5-1-5.2-3.8-5.2-1 0-1.9.6-2.4 1.4-1.2 2.1-.7 6.3 1.2 10.6-1.1 3.1-2.1 6.1-4.9 11.4-2.9 1.2-9 4-9.5 7-.2.9.1 1.8.8 2.5.7.6 1.6.9 2.5.9 3.7 0 7.3-5.1 9.8-9.4 2.1-.7 5.4-1.7 8.7-2.3 3.9 3.4 7.3 3.9 9.1 3.9 2.4 0 3.3-1 3.6-1.9.5-1 .2-2.1-.5-2.9zm-2.5 1.7c-.1.7-1 1.4-2.6 1-1.9-.5-3.6-1.4-5.1-2.6 1.3-.2 4.2-.5 6.3-.1.8.2 1.6.7 1.4 1.7zM25.8 12.3c.2-.3.5-.5.8-.5.9 0 1.1 1.1 1.1 2-.1 2.1-.5 4.2-1.2 6.2-1.5-4-1.2-6.8-.7-7.7zm-.2 19.4c.8-1.6 1.9-4.4 2.3-5.6.9 1.5 2.4 3.3 3.2 4.1 0 .1-3.1.7-5.5 1.5zm-5.9 4c-2.3 3.8-4.7 6.2-6 6.2-.2 0-.4-.1-.6-.2-.3-.2-.4-.5-.3-.9.3-1.4 2.9-3.3 6.9-5.1z" fill="#fff" /></svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="item-text" style={{ paddingLeft: '5px' }}>
                                                                        <SoftTypography variant="h6" fontWeight="medium" style={{ color: '#718096', position: 'relative', top: '3px', fontSize: '8px' }}>Auftrag #{data?.data?.order_id}</SoftTypography>
                                                                        <SoftTypography variant="caption" color="text" style={{ position: 'relative', top: '-7px' }} fontSize="8px" fontWeight="regular">erstellt {dateFormat(data?.data?.order_created_at, "dd.mm.yyyy")}</SoftTypography>
                                                                    </div>
                                                                </div></a>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : <></>}

                                            {JSON.parse(Cookies.get('permissions')).indexOf("PAR") !== -1 ? (
                                                <div className={(JSON.parse(Cookies.get('permissions')).indexOf("OPR") === -1 ? 'tab-pane fade show active p-3' : 'tab-pane fade p-3')} id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                                    <div className="row">

                                                        {invoices && invoices.map((invoice) => (
                                                            <a style={{ width: '118px', float: 'left', marginRight: '5px' }} target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'invoice/' + invoice.long_id + '.pdf'}>
                                                                <div className="item" style={{ border: '1px solid #ddd', backgroundColor: '#F9F9F9', borderRadius: '3px', width: '118px', padding: '0px 8px', height: '32px' }}>
                                                                    <div className="item-icon">
                                                                        <div>
                                                                            <svg style={{ height: '30px', width: '15px' }} viewBox="0 0 56 54" xmlns="http://www.w3.org/2000/svg" width="2500" height="2411"><path d="M9.9 0h36.2C51.6 0 56 4.4 56 9.9v34.2c0 5.5-4.4 9.9-9.9 9.9H9.9C4.4 54 0 49.6 0 44.1V9.9C0 4.4 4.4 0 9.9 0z" fill="#718096" /><path d="M45 31.2c-2.6-2.7-9.7-1.6-11.4-1.4-2.5-2.4-4.2-5.3-4.8-6.3.9-2.7 1.5-5.4 1.6-8.3 0-2.5-1-5.2-3.8-5.2-1 0-1.9.6-2.4 1.4-1.2 2.1-.7 6.3 1.2 10.6-1.1 3.1-2.1 6.1-4.9 11.4-2.9 1.2-9 4-9.5 7-.2.9.1 1.8.8 2.5.7.6 1.6.9 2.5.9 3.7 0 7.3-5.1 9.8-9.4 2.1-.7 5.4-1.7 8.7-2.3 3.9 3.4 7.3 3.9 9.1 3.9 2.4 0 3.3-1 3.6-1.9.5-1 .2-2.1-.5-2.9zm-2.5 1.7c-.1.7-1 1.4-2.6 1-1.9-.5-3.6-1.4-5.1-2.6 1.3-.2 4.2-.5 6.3-.1.8.2 1.6.7 1.4 1.7zM25.8 12.3c.2-.3.5-.5.8-.5.9 0 1.1 1.1 1.1 2-.1 2.1-.5 4.2-1.2 6.2-1.5-4-1.2-6.8-.7-7.7zm-.2 19.4c.8-1.6 1.9-4.4 2.3-5.6.9 1.5 2.4 3.3 3.2 4.1 0 .1-3.1.7-5.5 1.5zm-5.9 4c-2.3 3.8-4.7 6.2-6 6.2-.2 0-.4-.1-.6-.2-.3-.2-.4-.5-.3-.9.3-1.4 2.9-3.3 6.9-5.1z" fill="#fff" /></svg>
                                                                        </div>
                                                                    </div>

                                                                    <div className="item-text" style={{ paddingLeft: '5px' }}>
                                                                        <SoftTypography variant="h6" fontWeight="medium" style={{ color: '#718096', position: 'relative', top: '3px', fontSize: '8px' }}>RECHNUNG #{invoice.id}</SoftTypography>
                                                                        <SoftTypography variant="caption" color="text" style={{ position: 'relative', top: '-7px' }} fontSize="8px" fontWeight="regular">erstellt {dateFormat(invoice.created_at, "dd.mm.yyyy")}</SoftTypography>
                                                                    </div>

                                                                </div>
                                                            </a>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : <></>}

                                            {JSON.parse(Cookies.get('permissions')).indexOf("POPR") !== -1 ? (
                                                <div className={(JSON.parse(Cookies.get('permissions')).indexOf("PAR") === -1 ? 'tab-pane fade show active p-3' : 'tab-pane fade p-3')} id="abnahme-tab-pane" role="tabpanel" aria-labelledby="abnahme-tab" tabIndex="0">
                                                    <div className="row">
                                                        {ac?.id && (
                                                            <a target={"_blank"} style={{ width: '118px', float: 'left', marginRight: '5px', marginBottom: '5px' }} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'pdf/' + ac?.long_id + '.pdf'}>
                                                                <div className="item" style={{ border: '1px solid #ddd', backgroundColor: '#F9F9F9', borderRadius: '3px', width: '118px', padding: '0px 8px', height: '32px' }}>
                                                                    <div className="item-icon">
                                                                        <div>
                                                                            <svg style={{ height: '30px', width: '15px' }} viewBox="0 0 56 54" xmlns="http://www.w3.org/2000/svg" width="2500" height="2411"><path d="M9.9 0h36.2C51.6 0 56 4.4 56 9.9v34.2c0 5.5-4.4 9.9-9.9 9.9H9.9C4.4 54 0 49.6 0 44.1V9.9C0 4.4 4.4 0 9.9 0z" fill="#718096" /><path d="M45 31.2c-2.6-2.7-9.7-1.6-11.4-1.4-2.5-2.4-4.2-5.3-4.8-6.3.9-2.7 1.5-5.4 1.6-8.3 0-2.5-1-5.2-3.8-5.2-1 0-1.9.6-2.4 1.4-1.2 2.1-.7 6.3 1.2 10.6-1.1 3.1-2.1 6.1-4.9 11.4-2.9 1.2-9 4-9.5 7-.2.9.1 1.8.8 2.5.7.6 1.6.9 2.5.9 3.7 0 7.3-5.1 9.8-9.4 2.1-.7 5.4-1.7 8.7-2.3 3.9 3.4 7.3 3.9 9.1 3.9 2.4 0 3.3-1 3.6-1.9.5-1 .2-2.1-.5-2.9zm-2.5 1.7c-.1.7-1 1.4-2.6 1-1.9-.5-3.6-1.4-5.1-2.6 1.3-.2 4.2-.5 6.3-.1.8.2 1.6.7 1.4 1.7zM25.8 12.3c.2-.3.5-.5.8-.5.9 0 1.1 1.1 1.1 2-.1 2.1-.5 4.2-1.2 6.2-1.5-4-1.2-6.8-.7-7.7zm-.2 19.4c.8-1.6 1.9-4.4 2.3-5.6.9 1.5 2.4 3.3 3.2 4.1 0 .1-3.1.7-5.5 1.5zm-5.9 4c-2.3 3.8-4.7 6.2-6 6.2-.2 0-.4-.1-.6-.2-.3-.2-.4-.5-.3-.9.3-1.4 2.9-3.3 6.9-5.1z" fill="#fff" /></svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="item-text" style={{ paddingLeft: '5px' }}>
                                                                        <SoftTypography variant="h6" fontWeight="medium" style={{ color: '#718096', position: 'relative', top: '3px', fontSize: '8px' }}>AC #{ac?.id}</SoftTypography>
                                                                        <SoftTypography variant="caption" color="text" style={{ position: 'relative', top: '-7px' }} fontSize="8px" fontWeight="regular">erstellt {dateFormat(ac?.created_at, "dd.mm.yyyy")}</SoftTypography>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )}

                                                        {dc?.id && (
                                                            <a target={"_blank"} style={{ width: '118px', float: 'left', marginRight: '5px', marginBottom: '5px' }} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'pdf/' + dc?.long_id + '.pdf'}>
                                                                <div className="item" style={{ border: '1px solid #ddd', backgroundColor: '#F9F9F9', borderRadius: '3px', width: '118px', padding: '0px 8px', height: '32px' }}>
                                                                    <div className="item-icon">
                                                                        <div>
                                                                            <svg style={{ height: '30px', width: '15px' }} viewBox="0 0 56 54" xmlns="http://www.w3.org/2000/svg" width="2500" height="2411"><path d="M9.9 0h36.2C51.6 0 56 4.4 56 9.9v34.2c0 5.5-4.4 9.9-9.9 9.9H9.9C4.4 54 0 49.6 0 44.1V9.9C0 4.4 4.4 0 9.9 0z" fill="#718096" /><path d="M45 31.2c-2.6-2.7-9.7-1.6-11.4-1.4-2.5-2.4-4.2-5.3-4.8-6.3.9-2.7 1.5-5.4 1.6-8.3 0-2.5-1-5.2-3.8-5.2-1 0-1.9.6-2.4 1.4-1.2 2.1-.7 6.3 1.2 10.6-1.1 3.1-2.1 6.1-4.9 11.4-2.9 1.2-9 4-9.5 7-.2.9.1 1.8.8 2.5.7.6 1.6.9 2.5.9 3.7 0 7.3-5.1 9.8-9.4 2.1-.7 5.4-1.7 8.7-2.3 3.9 3.4 7.3 3.9 9.1 3.9 2.4 0 3.3-1 3.6-1.9.5-1 .2-2.1-.5-2.9zm-2.5 1.7c-.1.7-1 1.4-2.6 1-1.9-.5-3.6-1.4-5.1-2.6 1.3-.2 4.2-.5 6.3-.1.8.2 1.6.7 1.4 1.7zM25.8 12.3c.2-.3.5-.5.8-.5.9 0 1.1 1.1 1.1 2-.1 2.1-.5 4.2-1.2 6.2-1.5-4-1.2-6.8-.7-7.7zm-.2 19.4c.8-1.6 1.9-4.4 2.3-5.6.9 1.5 2.4 3.3 3.2 4.1 0 .1-3.1.7-5.5 1.5zm-5.9 4c-2.3 3.8-4.7 6.2-6 6.2-.2 0-.4-.1-.6-.2-.3-.2-.4-.5-.3-.9.3-1.4 2.9-3.3 6.9-5.1z" fill="#fff" /></svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="item-text" style={{ paddingLeft: '5px' }}>
                                                                        <SoftTypography variant="h6" fontWeight="medium" style={{ color: '#718096', position: 'relative', top: '3px', fontSize: '8px' }}>DC #{dc?.id}</SoftTypography>
                                                                        <SoftTypography variant="caption" color="text" style={{ position: 'relative', top: '-7px' }} fontSize="8px" fontWeight="regular">erstellt {dateFormat(dc?.created_at, "dd.mm.yyyy")}</SoftTypography>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )}

                                                        {data?.ac_reclamation_abnahmeprotokolls ? data?.ac_reclamation_abnahmeprotokolls.map((reclamation_abnahmeprotokoll, key) => (
                                                            <a key={key} target={"_blank"} style={{ width: '118px', float: 'left', marginRight: '5px', marginBottom: '5px' }} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'reclamation/acabname/' + reclamation_abnahmeprotokoll?.id}>
                                                                <div className="item" style={{ border: '1px solid #ddd', backgroundColor: '#F9F9F9', borderRadius: '3px', width: '118px', padding: '0px 8px', height: '32px' }}>
                                                                    <div className="item-icon">
                                                                        <div>
                                                                            <svg style={{ height: '30px', width: '15px' }} viewBox="0 0 56 54" xmlns="http://www.w3.org/2000/svg" width="2500" height="2411"><path d="M9.9 0h36.2C51.6 0 56 4.4 56 9.9v34.2c0 5.5-4.4 9.9-9.9 9.9H9.9C4.4 54 0 49.6 0 44.1V9.9C0 4.4 4.4 0 9.9 0z" fill="#718096" /><path d="M45 31.2c-2.6-2.7-9.7-1.6-11.4-1.4-2.5-2.4-4.2-5.3-4.8-6.3.9-2.7 1.5-5.4 1.6-8.3 0-2.5-1-5.2-3.8-5.2-1 0-1.9.6-2.4 1.4-1.2 2.1-.7 6.3 1.2 10.6-1.1 3.1-2.1 6.1-4.9 11.4-2.9 1.2-9 4-9.5 7-.2.9.1 1.8.8 2.5.7.6 1.6.9 2.5.9 3.7 0 7.3-5.1 9.8-9.4 2.1-.7 5.4-1.7 8.7-2.3 3.9 3.4 7.3 3.9 9.1 3.9 2.4 0 3.3-1 3.6-1.9.5-1 .2-2.1-.5-2.9zm-2.5 1.7c-.1.7-1 1.4-2.6 1-1.9-.5-3.6-1.4-5.1-2.6 1.3-.2 4.2-.5 6.3-.1.8.2 1.6.7 1.4 1.7zM25.8 12.3c.2-.3.5-.5.8-.5.9 0 1.1 1.1 1.1 2-.1 2.1-.5 4.2-1.2 6.2-1.5-4-1.2-6.8-.7-7.7zm-.2 19.4c.8-1.6 1.9-4.4 2.3-5.6.9 1.5 2.4 3.3 3.2 4.1 0 .1-3.1.7-5.5 1.5zm-5.9 4c-2.3 3.8-4.7 6.2-6 6.2-.2 0-.4-.1-.6-.2-.3-.2-.4-.5-.3-.9.3-1.4 2.9-3.3 6.9-5.1z" fill="#fff" /></svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="item-text" style={{ paddingLeft: '5px' }}>
                                                                        <SoftTypography variant="h6" fontWeight="medium" style={{ color: '#718096', position: 'relative', top: '3px', fontSize: '8px' }}>AC #R-{reclamation_abnahmeprotokoll?.reclamation_id}</SoftTypography>
                                                                        <SoftTypography variant="caption" color="text" style={{ position: 'relative', top: '-7px' }} fontSize="8px" fontWeight="regular">erstellt {dateFormat(reclamation_abnahmeprotokoll?.created_at, "dd.mm.yyyy")}</SoftTypography>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )) : ''}

                                                        {data?.dc_reclamation_abnahmeprotokolls ? data?.dc_reclamation_abnahmeprotokolls.map((reclamation_abnahmeprotokoll, key) => (
                                                            <a key={key} target={"_blank"} style={{ width: '118px', float: 'left', marginRight: '5px', marginBottom: '5px' }} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'reclamation/dcabname/' + reclamation_abnahmeprotokoll?.id}>
                                                                <div className="item" style={{ border: '1px solid #ddd', backgroundColor: '#F9F9F9', borderRadius: '3px', width: '118px', padding: '0px 8px', height: '32px' }}>
                                                                    <div className="item-icon">
                                                                        <div>
                                                                            <svg style={{ height: '30px', width: '15px' }} viewBox="0 0 56 54" xmlns="http://www.w3.org/2000/svg" width="2500" height="2411"><path d="M9.9 0h36.2C51.6 0 56 4.4 56 9.9v34.2c0 5.5-4.4 9.9-9.9 9.9H9.9C4.4 54 0 49.6 0 44.1V9.9C0 4.4 4.4 0 9.9 0z" fill="#718096" /><path d="M45 31.2c-2.6-2.7-9.7-1.6-11.4-1.4-2.5-2.4-4.2-5.3-4.8-6.3.9-2.7 1.5-5.4 1.6-8.3 0-2.5-1-5.2-3.8-5.2-1 0-1.9.6-2.4 1.4-1.2 2.1-.7 6.3 1.2 10.6-1.1 3.1-2.1 6.1-4.9 11.4-2.9 1.2-9 4-9.5 7-.2.9.1 1.8.8 2.5.7.6 1.6.9 2.5.9 3.7 0 7.3-5.1 9.8-9.4 2.1-.7 5.4-1.7 8.7-2.3 3.9 3.4 7.3 3.9 9.1 3.9 2.4 0 3.3-1 3.6-1.9.5-1 .2-2.1-.5-2.9zm-2.5 1.7c-.1.7-1 1.4-2.6 1-1.9-.5-3.6-1.4-5.1-2.6 1.3-.2 4.2-.5 6.3-.1.8.2 1.6.7 1.4 1.7zM25.8 12.3c.2-.3.5-.5.8-.5.9 0 1.1 1.1 1.1 2-.1 2.1-.5 4.2-1.2 6.2-1.5-4-1.2-6.8-.7-7.7zm-.2 19.4c.8-1.6 1.9-4.4 2.3-5.6.9 1.5 2.4 3.3 3.2 4.1 0 .1-3.1.7-5.5 1.5zm-5.9 4c-2.3 3.8-4.7 6.2-6 6.2-.2 0-.4-.1-.6-.2-.3-.2-.4-.5-.3-.9.3-1.4 2.9-3.3 6.9-5.1z" fill="#fff" /></svg>
                                                                        </div>
                                                                    </div>
                                                                    <div className="item-text" style={{ paddingLeft: '5px' }}>
                                                                        <SoftTypography variant="h6" fontWeight="medium" style={{ color: '#718096', position: 'relative', top: '3px', fontSize: '8px' }}>DC #R-{reclamation_abnahmeprotokoll?.reclamation_id}</SoftTypography>
                                                                        <SoftTypography variant="caption" color="text" style={{ position: 'relative', top: '-7px' }} fontSize="8px" fontWeight="regular">erstellt {dateFormat(reclamation_abnahmeprotokoll?.created_at, "dd.mm.yyyy")}</SoftTypography>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )) : ''}



                                                    </div>
                                                </div>
                                            ) : <></>}

                                        </div>
                                    </div>
                                </div>

                                <div className="card mt-3">
                                    <div className="card-body" style={{ padding: '15px' }}>
                                        <SoftBox>
                                            <div className="row">
                                                <div className="col-sm-7"><SoftTypography variant="caption" fontWeight="bold" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Systeminformationen:</SoftTypography></div>
                                                <div className="col-sm-5"><SoftTypography variant="caption" fontWeight="bold" style={{ color: '#0048B1', fontSize: '0.875rem' }}> Allgemein: </SoftTypography> </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <div className="row">
                                                        <div className="col-sm-3"><SoftTypography variant="caption" color="text" fontSize="11px" style={{ display: 'block', lineHeight: '1.4' }} fontWeight="regular">Speicher</SoftTypography></div>
                                                        <div className="col-sm-9"><SoftTypography variant="caption" color="text" fontSize="11px" style={{ display: 'block', lineHeight: '1.4' }} fontWeight="bold">{data?.data?.speicher_eingeben ? data.data.speicher_eingeben == 'yes' ? data.data.custom_speicher_name != null ? data.data.custom_speicher_name : '--' : data.data.speicher_name != null ? data.data.speicher_name : '--' : '--'}</SoftTypography> </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3"><SoftTypography variant="caption" color="text" fontSize="11px" style={{ display: 'block', lineHeight: '1.4' }} fontWeight="regular">Modul</SoftTypography></div>
                                                        <div className="col-sm-9"><SoftTypography variant="caption" color="text" style={{ lineHeight: '1.4', display: 'block' }} fontSize="11px" fontWeight="bold">{data?.data?.module_eingeben ? data.data.module_eingeben == 'yes' ? data.data.custom_module_short_title != null ? data.data.custom_module_short_title : '--' : data.data.module_short_title != null ? data.data.module_short_title : '--' : '--'}</SoftTypography> </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3"><SoftTypography variant="caption" color="text" fontSize="11px" style={{ display: 'block', lineHeight: '1.4' }} fontWeight="regular">Eig.verbr.</SoftTypography></div>
                                                        <div className="col-sm-9"><SoftTypography variant="caption" color="text" style={{ lineHeight: '1.4', display: 'block' }} fontSize="11px" fontWeight="bold">{data?.data?.own_consumption} Kw/h</SoftTypography> </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3"><SoftTypography variant="caption" color="text" fontSize="11px" style={{ display: 'block', lineHeight: '1.4' }} fontWeight="medium">Wechselr.</SoftTypography></div>
                                                        <div className="col-sm-9"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="12px" fontWeight="bold">{data?.data?.wechselrichter_eingeben ? data.data.wechselrichter_eingeben == 'yes' ? data.data.custom_wechselrichter_titel != null ? data.data.custom_wechselrichter_titel : '--' : data.data.wechselrichter_name != null ? data.data.wechselrichter_name : '--' : '--'}</SoftTypography> </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="row">
                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="regular">Stromk. Jahr:</SoftTypography></div>
                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="bold">{data?.data?.eigenverbrauch} €</SoftTypography> </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="regular">Stromv. Jahr:</SoftTypography></div>
                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="bold">{data?.data?.stromkosten} Kw/h</SoftTypography> </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="medium">Kwh Preis:</SoftTypography></div>
                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="bold">{data?.data?.kwh_preis} €</SoftTypography> </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="regular">Baufläche:</SoftTypography></div>
                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="bold">{data?.data?.ges_dachflache} qm</SoftTypography> </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="regular">Dachtyp:</SoftTypography></div>
                                                        <div className="col-sm-6"><SoftTypography variant="caption" color="text" style={{ display: 'block', lineHeight: '1.4' }} fontSize="11px" fontWeight="bold">{data?.data?.dachtyp}</SoftTypography> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SoftBox>
                                    </div>
                                </div>

                                <div className="card mt-3">
                                    <div className="card-body" style={{ padding: '15px', height: '315px', overflowY: 'auto' }}>
                                        <SoftBox>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <SoftTypography variant="h6" fontWeight="bold" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Service Management:</SoftTypography>
                                                    <SoftTypography variant="caption" color="text" fontWeight="medium">Reklamationen & Tickets</SoftTypography>
                                                </div>
                                                <div className="col-sm-12">
                                                    {/* <Reclamation projectId={params.id} /> */}
                                                    {/* <Tickets projectId={params.id} /> */}
                                                </div>
                                            </div>
                                        </SoftBox>
                                    </div>
                                </div>
                            </div>
                            <div className="col" style={{ position: 'relative', height: '530px' }}>
                                {data?.data?.lat && data?.data?.lng && mapDisplay ? (
                                    <>
                                        <GoogleMap
                                            id='project-location'
                                            mapContainerStyle={{ height: '210px', width: '100%', borderRadius: '7px' }}
                                            mapContainerClassName={'googleMapContainer'}
                                            zoom={19}
                                            center={{
                                                lat: parseFloat(data?.data?.lat),
                                                lng: parseFloat(data?.data?.lng),
                                                //lat: 51.1657,
                                                //lng: 10.4515,
                                            }}
                                            options={{
                                                disableDefaultUI: true,
                                                zoomControl: true,
                                                mapTypeId: 'satellite',
                                            }}
                                        />
                                    </>
                                ) : (
                                    <div style={{ height: '210px', textAlign: 'center', borderRadius: '7px', lineHeight: '210px', background: "url('" + defaultMapImg + "')", backgroundPosition: 'center' }}>
                                        <div style={{ borderRadius: '7px', background: 'rgb(0 0 0 / 60%)' }}>
                                            <a style={{ color: '#ffffff', textDecoration: 'underline !important' }} onClick={() => { setMapDisplay(true) }}>Click for Google Maps</a>
                                        </div>
                                    </div>
                                )}
                                <ProjectPost id={params.id} />
                            </div> </>}


                        <div className="col">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card mb-3">
                                        <div className="card-body p-3">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                        <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                                    </div>
                                                </div>
                                                <div className="ms-3">
                                                    <div className="numbers">
                                                        <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr')}:</p>
                                                        <h5 className="font-weight-bolder mb-0">
                                                            {params?.id ? params?.id : '-'}
                                                        </h5>
                                                    </div>
                                                </div>

                                                {data?.data?.id && data?.data?.stornieren != 0 ? (
                                                    <div className="text-end ms-3 mt-2">
                                                        <button
                                                            className={"btn mb-0 " + (data?.data?.stornieren == 1 ? "btn-danger" : data?.data?.stornieren == 2 ? "btn-success" : "")}
                                                            onClick={(e) => {
                                                                storno(e);
                                                            }}
                                                        >{t(data?.data?.stornieren == 1 ? "Projekt Storniert" : data?.data?.stornieren == 2 ? "Storno aufgehoben" : "Stornieren")}</button>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mb-3">
                                        <div className="card-body p-3">
                                            <h6 style={{ fontWeight: '700', fontSize: '0.875rem' }}>{t('Status')}</h6>
                                            {params?.id ? <ProjectStatus projectid={params?.id} status={data?.data?.status} /> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="callcenter-area" style={{ padding: '', height: '470px', overflowY: 'auto' }}>
                                <Timeline refresh={refresh2} />
                            </div>
                        </div>
                    </div>
                )}


                {/* <edit area></edit> */}
                {/* </div> */}

                {renderSuccessSB}
            </div>

            <button className="d-none" id="callcenterRefesh3" onClick={() => { setRefesh3(refesh3 + 1); }}>refesh3</button>
        </>;
}

export default CallCenter;
